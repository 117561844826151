<template>
  <div>
    <a-modal
      v-model="visible"
      :width="1200"
      :title="$t('上架单打印配置')"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      centered
      okText="保存"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
        <a-row>
          <a-col :span="12" :offset="6">
            <a-form-item :label="$t('标题文字')">
              <a-input v-model="formItem.title" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item :label="$t('单据字段')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-select v-model="formItem.headerFields" mode="multiple" style="width: 100%">
                <a-select-option value="receipt_order_number">入库单号</a-select-option>
                <a-select-option value="warehouse">仓库</a-select-option>
                <a-select-option value="client">货主</a-select-option>
                <a-select-option value="order_type">订单类型</a-select-option>
                <a-select-option value="counterparty">往来单位</a-select-option>
                <a-select-option value="external_number">往来单位单号</a-select-option>
                <a-select-option value="arrival_date">预计到货日</a-select-option>
                <a-select-option value="remark">备注</a-select-option>
                <a-select-option value="creator">创建人</a-select-option>
                <a-select-option value="create_time">创建时间</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-divider />

          <a-col :span="24">
            <a-form-item :label="$t('表头字段')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-select v-model="formItem.tableFields" mode="multiple" style="width: 100%">
                <a-select-option value="material_number">货品编号</a-select-option>
                <a-select-option value="material_name">货品名称</a-select-option>
                <a-select-option value="material_barcode">货品条码</a-select-option>
                <a-select-option value="total_quantity">数量</a-select-option>
                <a-select-option value="length">长</a-select-option>
                <a-select-option value="width">宽</a-select-option>
                <a-select-option value="height">高</a-select-option>
                <a-select-option value="volume">体积</a-select-option>
                <a-select-option value="weight">重量</a-select-option>
                <a-select-option value="spec">规格</a-select-option>
                <a-select-option value="unit">单位</a-select-option>
                <a-select-option value="batch_number">批次编号</a-select-option>
                <a-select-option value="production_date">生产日期</a-select-option>
                <a-select-option value="recommend_areas">推荐库区</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item :label="$t('表格行数')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input-number v-model="formItem.tableRows" :min="1" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item :label="$t('表格排序')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-space :size="16">
                <div>
                  <a-radio-group v-model="formItem.tableSortType" button-style="solid">
                    <a-radio-button value="positive">正序</a-radio-button>
                    <a-radio-button value="reverse">倒序</a-radio-button>
                  </a-radio-group>
                </div>
                <div>
                  <a-select v-model="formItem.tableSortField" allowClear style="width: 256px">
                    <a-select-option value="total_quantity">数量</a-select-option>
                  </a-select>
                </div>
              </a-space>
            </a-form-item>
          </a-col>

          <a-divider />

          <a-col :span="24">
            <a-form-item :label="$t('与表格间距')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input-number v-model="formItem.tableMarginBottom" :min="1" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('左部文字')">
              <a-input v-model="formItem.leftTextContent" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item :label="$t('右部文字')">
              <a-input v-model="formItem.rightTextContent" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item :label="$t('左部文字属性')">
              <a-space :size="16">
                <div>字号: <a-input-number v-model="formItem.leftTextFontSize" :min="12" /></div>
                <div>
                  <a-radio-group v-model="formItem.leftTextAlign" button-style="solid" style="text-align: center">
                    <a-radio-button value="left"><a-icon type="pic-left" /></a-radio-button>
                    <a-radio-button value="center"><a-icon type="pic-center" /></a-radio-button>
                    <a-radio-button value="right"><a-icon type="pic-right" /></a-radio-button>
                  </a-radio-group>
                </div>
                <div>
                  <a-checkbox v-model="formItem.leftTextFontWeight">粗体</a-checkbox>
                  <a-checkbox v-model="formItem.leftTextFontItalic">斜体</a-checkbox>
                </div>
              </a-space>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item :label="$t('右部文字属性')">
              <a-space :size="16">
                <div>字号: <a-input-number v-model="formItem.rightTextFontSize" :min="12" /></div>
                <div>
                  <a-radio-group v-model="formItem.rightTextAlign" button-style="solid" style="text-align: center">
                    <a-radio-button value="left"><a-icon type="pic-left" /></a-radio-button>
                    <a-radio-button value="center"><a-icon type="pic-center" /></a-radio-button>
                    <a-radio-button value="right"><a-icon type="pic-right" /></a-radio-button>
                  </a-radio-group>
                </div>
                <div>
                  <a-checkbox v-model="formItem.rightTextFontWeight">粗体</a-checkbox>
                  <a-checkbox v-model="formItem.rightTextFontItalic">斜体</a-checkbox>
                </div>
              </a-space>
            </a-form-item>
          </a-col>
          <a-divider />

          <a-col :span="24">
            <a-form-item :label="$t('底部文字')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input v-model="formItem.bottomTextContent" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item :label="$t('底部文字属性')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-space :size="16">
                <div>字号: <a-input-number v-model="formItem.bottomTextFontSize" :min="12" /></div>
                <div>
                  <a-radio-group v-model="formItem.bottomTextAlign" button-style="solid" style="text-align: center">
                    <a-radio-button value="left"><a-icon type="pic-left" /></a-radio-button>
                    <a-radio-button value="center"><a-icon type="pic-center" /></a-radio-button>
                    <a-radio-button value="right"><a-icon type="pic-right" /></a-radio-button>
                  </a-radio-group>
                </div>
                <div>
                  <a-checkbox v-model="formItem.bottomTextFontWeight">粗体</a-checkbox>
                  <a-checkbox v-model="formItem.bottomTextFontItalic">斜体</a-checkbox>
                </div>
              </a-space>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item :label="$t('与底边间距')" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
              <a-input-number v-model="formItem.bottomTextBottom" :min="1" style="width: 100%" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { printConfigsSave } from "@/api/system";

export default {
  props: ["visible"],
  model: { prop: "visible", event: "cancel" },
  computed: {
    configItem() {
      return this.$store.state.user.shelveOrderPrintConfig;
    },
  },
  data() {
    return {
      confirmLoading: false,
      formItem: {},
    };
  },
  methods: {
    handleConfirm() {
      this.confirmLoading = true;
      printConfigsSave({ shelve_order_print_config: this.formItem })
        .then(() => {
          this.handleCancel();
          this.$router.go(0);
        })
        .finally(() => (this.confirmLoading = false));
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.formItem = { ...this.configItem };
      }
    },
  },
};
</script>

<style scoped></style>
